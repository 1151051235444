import React from 'react'
import styles from './Teacherscorner.module.css'

const Teacherscorner = () => {
  return (
    <>
        <div className={styles.teacherscorner}>
            <div className={styles.teacherscorner_head}>
                <div className={styles.overlay}></div>
                <div className={styles.teacherscorner_head_text}>
                    <h1>Teachers Corner</h1>
                </div>
            </div>

            <div className={styles.teacherscorner_main}>

            </div>
        </div>
    </>
  )
}

export default Teacherscorner