import React from 'react';
import styles from './Holiday.module.css';

const Holiday = () => {
  return (
    <>
      <div className={styles.holiday}>
        <div className={styles.holiday_head}>           
          <div className={styles.overlay}></div>
          <div className={styles.holiday_head_text}>
            <h1>Holiday</h1>
          </div>
        </div>

        <h2>Programme For 2022 - 2023</h2>
        <div className={styles.holiday_main}>
           
          <table className={styles.holiday_table}>
            <thead>
              <tr>
                <th>Schedule</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Second Term</td>
                <td>04-Oct-21</td>
                <td>31-Mar-22</td>
              </tr>
              <tr>
                <td>First Term (Before Summer Vacation)</td>
                <td>09-Apr-21</td>
                <td>05-May-21</td>
              </tr>
              <tr>
                <td>First Term (After Summer Vacation)</td>
                <td>07-Jun-21</td>
                <td>01-Oct-21</td>
              </tr>
              <tr>
                <td>Diwali Break</td>
                <td>01-Nov-21</td>
                <td>06-Nov-21</td>
              </tr>
              <tr>
                <td>Christmas Break</td>
                <td>25-Dec-21</td>
                <td>01-Jan-22</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Holiday;
