import React from 'react';
import styles from './Testimonials.module.css';
import toppers from '../../../Assets/toppers.mp4';

const Testimonials = () => {
  return (
    <section className={styles.testimonials_section}>
      <h2 className={styles.heading}>Toppers Talk</h2>
      <div className={styles.flexbox}>
        <div className={styles.content_box}>
          <h3>Celebrating the Achievements of Our 10th & 12th Toppers at Hillgreen High School & Junior College</h3>
          <p>
            At Hillgreen High School & Junior College, we proudly celebrate the exceptional achievements of our 10th and 12th-grade toppers. In our latest video, "Toppers Talk," they share their inspiring journeys, offering study tips, time management strategies, and practical advice for academic success. They also express gratitude to their teachers, parents, and peers. Join us in celebrating their dedication, resilience, and passion for learning, and draw inspiration from their remarkable stories.
          </p>
        </div>
        <div className={styles.frame}>
          <video className={styles.video} src={toppers} controls title="Toppers Talk Video" aria-label="Toppers Talk Video" />
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
