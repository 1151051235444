import React from 'react';
import styles from './Slider.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderHero = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <>
            <div className={styles.sliderContainer}>
                <Slider {...settings}  >
                    {/* Slide 1 */}
                    <div className={`${styles.slide1} ${styles.slide}`}>
                        {/* <div className={`${styles.slide_content} ${styles.slide_content1}`}>
                            <h3>Discovering The Talent & Building Confidence</h3>
                            <h2>CBSE Affiliated</h2>
                            <h1>Hillgreen High School <br /> & Jr. Collage</h1>
                        </div> */}
                    </div>

                    {/* Slide 2 */}
                    <div className={`${styles.slide2} ${styles.slide}`}>
                        {/* <div className={`${styles.slide_content} ${styles.slide_content2}`}>
                        <h3>Discovering The Talent & Building Confidence</h3>
                            <h2>CBSE Affiliated</h2>
                            <h1>Hillgreen High School <br /> & Jr. Collage</h1>
                        </div> */}
                    </div>

                    {/* Slide 3 */}
                    <div className={`${styles.slide3} ${styles.slide}`}>
                        <div className={`${styles.slide_content} ${styles.slide_content3}`}>
                        
                        </div>
                    </div>
                </Slider>

            </div>
           
        </>
    );
};

export default SliderHero;
