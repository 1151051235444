import React from 'react'
import styles from './Studentscorner.module.css'


// import images and other files 
import AASAD from '../../../Assets/Activities/AASAD.webp'
import TANISHKA from '../../../Assets/Activities/TANISHKA.webp'
import MD_SAKHEB from '../../../Assets/Activities/MD_SAKHEB.webp'
import KRISHNA from '../../../Assets/Activities/KRISHNA.webp'
import KULJEET from '../../../Assets/Activities/KULJEET.webp'
import AARUSHI from '../../../Assets/Activities/AARUSHI.webp'
import AARUSHI2 from '../../../Assets/Activities/AARUSHI2.webp'
import ARYAN from '../../../Assets/Activities/ARYAN.webp'
import DISHA from '../../../Assets/Activities/DISHA.webp'
import HAREESH from '../../../Assets/Activities/HAREESH.webp'
import REHAAN from '../../../Assets/Activities/REHAAN.webp'
import SAMRUDDHI from '../../../Assets/Activities/SAMRUDDHI.webp'
import SAMRUDDHI2 from '../../../Assets/Activities/SAMRUDDHI BAGADE.webp'




const Studemts_Corner = () => {
  return (
    <>
    <div className={styles.studemts_corner}>
        <div className={styles.studemts_corner_head}>
            <div className={styles.overlay}></div>
            <div className={styles.studemts_corner_head_text}>
                <h1>Student's Corner</h1>
            </div>
        </div>
    
        <div className={styles.studemts_corner_main}>
            <h1>Paintings</h1>
            <div className={styles.studemts_corner_grid}>
                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={AASAD} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>AASAD BAIG</h2>
                            <p>CLASS - II CHANAKYA</p>
                    </div>
                </div>


                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={TANISHKA} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>TANISHKA PATOLE</h2>
                            <p>CLASS - V ALEXANDER</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={MD_SAKHEB} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>MD SAKHEB</h2>
                            <p>CLASS - VI NEWTON</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={KRISHNA} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>KRISHNA AMRIT</h2>
                            <p>CLASS - VII KALAM</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={KULJEET} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>KULJEET KAUR</h2>
                            <p>CLASS - VII KALAM</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={AARUSHI} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>AARUSHI NILANGEKAR</h2>
                            <p>CLASS - VII NEWTON</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={AARUSHI2} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>AARUSHI NILANGEKAR</h2>
                            <p>CLASS - VII NEWTON</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={ARYAN} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>ARYAN SHINDE</h2>
                            <p>CLASS - VIII KALAM</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={DISHA} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>DISHA</h2>
                            <p>CLASS - IX NEWTON</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={HAREESH} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>HAREESH MENON</h2>
                            <p>CLASS - IX NEWTON</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={REHAAN} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>REHAAN SAIKH</h2>
                            <p>CLASS - 9 RAMAN</p>
                    </div>
                </div>



                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={SAMRUDDHI} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>SAMRUDDHI BAGADE</h2>
                            <p>CLASS - X RAMAN</p>
                    </div>
                </div>


                <div className={styles.studemts_corner_grid_box}>
                    <div className={styles.grid_box_image}>
                            <img src={SAMRUDDHI2} alt='AASAD painting' />
                    </div>
                    <div className={styles.student_details}>
                            <h2>SAMRUDDHI BAGADE</h2>
                            <p>CLASS - X RAMAN</p>
                    </div>
                </div>


                
            </div>

        </div>
    </div>
    
    </>
  )
}

export default Studemts_Corner