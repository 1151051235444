import React from 'react'
import styles from './Assessments.module.css'

import assessment from '../../../Assets/Learning/curriculum_stud.webp'

const Assessments = () => {
  return (
    <>
        <div className={styles.assessments}>
            <div className={styles.assessments_head}>
                <div className={styles.overlay}></div>
                <div className={styles.assessments_head_text}>
                        <h1>Assessments</h1>
                </div>
            </div>

            <div className={styles.assessments_mian}>

                <div className={styles.assessments_fexbox}>
                        <div className={styles.assessments_box}>
                            <h2>Assessments and Promotions</h2>
                            <p>The students shall be assessed on a regular basis throughout the academic year and promotions to higher classes shall be based on the work done by each student throughout the year. The focus is on regular and continuous work, rather than a spurt at the end. Normally, in an academic year, two unit tests and two examinations shall be held. However, the number of unit tests and exams may change from time to time, if the school decides to do so. If a student is detained in a particular class for two consecutive academic years then the school may inform the parents to withdraw the student from this school.</p>
                            <br/>
                            <p>The medium of instruction is English in all the classes.</p>
                        </div>
                        <div className={styles.assessments_box}>
                            <img src={assessment} alt='Hillgreen Assesment' />
                        </div>
                    
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Assessments