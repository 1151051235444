import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './News.module.css';

// Array of months for tab navigation
const months = [
  "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December"
];

const News = () => {
  const [events, setEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Default to the current month
  const [expandedIndex, setExpandedIndex] = useState(-1); // To track the expanded event

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://hillgreenhighschool.in/fetch_news.php');
        console.log("Response data:", response.data);
        console.log("Response status:", response.status);

        // Check if response is an array
        if (Array.isArray(response.data)) {
          setEvents(response.data);
        } else {
          console.error('Response is not an array:', response.data);
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  // Filter events based on the selected month
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date);
    const monthName = months[eventDate.getMonth()];
    return monthName === selectedMonth;
  });

  // Toggle expanded description
  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <div className={styles.news}>
      <div className={styles.news_head}>
        <div className={styles.overlay}></div>
        <div className={styles.news_head_text}>
          <h1>News</h1>
        </div>
      </div>
      <div className={styles.news_tabs}>
        {months.map(month => (
          <button
            key={month}
            className={selectedMonth === month ? styles.active : ''}
            onClick={() => setSelectedMonth(month)}
          >
            {month}
          </button>
        ))}
      </div>
      <div className={styles.news_main}>
        <h1>{selectedMonth} Events</h1>
        {filteredEvents.length === 0 ? (
          <p>No events for {selectedMonth}</p>
        ) : (
          filteredEvents.map((event, index) => (
            <div className={styles.event} key={index}>
              <div className={styles.event_image}>
                <img 
                  src={`data:image/jpeg;base64,${event.image}`} 
                  alt={event.title} 
                  className={styles.newsImage} 
                />
              </div>
              <div className={styles.event_details}>
                <h2>{event.title}</h2>
                <p className={styles.event_date}>{event.date}</p>
                <p className={styles.event_description}>
                  {expandedIndex === index ? event.description : `${event.description.substring(0, 200)}...`}
                </p>
                <p className={styles.readmore} onClick={() => toggleReadMore(index)}>
                  {expandedIndex === index ? 'Read Less' : 'Read More'}{' '}
                  <span className={styles.readmore_arrow}>
                    &rarr;
                  </span>
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default News;
