import React from 'react';
import styles from './TestSchedule.module.css';

const TestSchedule = () => {
  const scheduleData = [
    { exam: 'PT 1 (Classes 1st to 10th)', date: '5th July 24 - 16th July 24', ptm: 'PT 1: 27th July 24' },
    { exam: 'PT 2 (Classes 2nd to 10th)', date: '18th Sept. 24 - 30th Sept. 24', ptm: 'PT 2: 19th October 24' },
    { exam: 'PT 3 (Classes 1st to 9th)', date: '2nd Dec. 24 - 10th Dec. 24', ptm: 'PT 3: 21st December 24' },
    { exam: 'Pre-board 1 (Class 10th)', date: '23rd Nov. 24 - 6th Dec. 24', ptm: 'PT 4: 24th March 25' },
    { exam: 'Pre-board 2 (Class 10th)', date: '6th Jan. 25 - 17th Jan. 25', ptm: '25th March 25' },
    { exam: 'PT 4 (Classes 1st to 9th)', date: '28th Feb. 25 - 13th Mar. 25', ptm: '25th March 25' },
  ];

  return (
    <div className={styles.testSchedule}>
      <div className={styles.testSchedule_head}>
        <div className={styles.overlay}></div>
        <div className={styles.testSchedule_head_text}>
          <h1>Test Schedule</h1>
        </div>
      </div>

      <div className={styles.testSchedule_main}>
        <table className={styles.testSchedule_table}>
          <thead>
            <tr>
              <th>Exams</th>
              <th>Start Date - End Date</th>
              <th>PTM Dates</th>
            </tr>
          </thead>
          <tbody>
            {scheduleData.map((item, index) => (
              <tr key={index}>
                <td>{item.exam}</td>
                <td>{item.date}</td>
                <td>{item.ptm}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestSchedule;
