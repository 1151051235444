import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';  // Import the styles for react-calendar
import styles from './Events.module.css';

const Events = () => {
  const [date, setDate] = useState(new Date());

  const onChange = date => {
    setDate(date);
    // Here you can handle the date change event and do something with the selected date
  };

  return (
    <>
      <div className={styles.events}>
        <div className={styles.events_head}>
          <div className={styles.overlay}></div>
          <div className={styles.event_head_text}>
            <h1>Archives: Events</h1>
          </div>
        </div>
       
        <div className={styles.events_main}>
          <div className={styles.event_mian_head}>
            <div className={styles.top_calendar}>
                <h1>Upcomming Events</h1>
              <Calendar onChange={onChange} value={date} />
            </div>                
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
