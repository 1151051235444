import React from 'react'
import styles from './OfflineAdmission.module.css'
import { Helmet } from 'react-helmet'


const OfflineAdmission = () => {
    return (
        <>
            {/* SEO */}
            <Helmet>
                <title>Offline Admission for Hillgreen High School | Apply Now</title>
                <meta name="description" content="Offline admission for Hillgreen High School. Visit the school office to collect the application form and prospectus. Submit the form with necessary documents to secure admission for the upcoming academic year." />
                <meta name="keywords" content="offline admission, admission form, Hillgreen High School, school admission, admission process, leaving certificate, application form" />
                <meta property="og:title" content="Offline Admission for Hillgreen High School | Apply Now" />
                <meta property="og:description" content="Submit your offline admission form at Hillgreen High School. Collect the application form and prospectus from the school office and ensure all documents are in order." />
                <meta property="og:image" content="URL_TO_IMAGE_HERE" />
                <meta property="og:url" content="https://www.hillgreenhighschool.in/offlineadmission" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Offline Admission for Hillgreen High School | Apply Now" />
                <meta name="twitter:description" content="Offline admission for Hillgreen High School. Collect the form from the school office, complete it, and submit it with required documents to finalize your admission." />
                <meta name="twitter:image" content="URL_TO_IMAGE_HERE" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="robots" content="index, follow" />
            </Helmet>


            <div className={styles.offlineadmission}>
                <div className={styles.offlineadmission_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.offlineadmission_head_text}>
                        <h1>Offline Admission</h1>

                    </div>
                </div>

                <div className={styles.offlineadmission_main}>
                    <div className={styles.offlineadmission_container}>
                        <h1>Admissions</h1>
                        <div className={styles.border_bottom}></div>
                        <div className={styles.offlineadmission_paragraph}>
                            <p>The application forms shall be available in the school office, along with the prospectus. Kindly go through the prospectus very carefully before filling the admission form. Normally, each form should be properly filled and submitted to the school office well in advance, for the following academic year.</p>
                            <br />
                            <p>All application forms shall be processed by the school.</p>
                            <br />
                            <p>If a student is offered admission, then the original leaving certificate, issued by the previous school, should be submitted to this school. Without the original leaving certificate of the previous school, no admission can be confirmed. While taking the leaving certificate of the previous school, please be absolutely certain that all the details are entered correctly, such as the spellings of the names, the date of birth, the class in which the student is studying, etc.</p>
                            <br />
                            <p>If a student comes from a school which is located in another state or union territory in India, then the leaving certificate issued by the previous school shall have to be counter signed by the appropriate educational authority of that state or union territory to show that the school is a recognised school.</p>
                            <br />
                            <p>For students coming from other countries, their leaving certificates shall have to be counter signed by the Indian Consulates or Embassies in those countries.</p>
                            <br />
                            <p>Without the counter signature of the appropriate authority, the leaving certificate of the previous school shall not be accepted and in such cases, the admission cannot be confirmed for those students who come from schools located outside Maharashtra State or abroad.</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OfflineAdmission