import React, { useState } from 'react';

import styles from './Calendar.module.css';
import Calendarimg from '../../../Assets/Calendar/December.png';
import events from '../../../Assets/Calendar/december_events.png'


const SchoolCalendar = () => {
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
  };

  const schoolDays = [
    { date: '2024-06-05', event: 'STORY TELLING COMPETITION', type: 'competition' },
    { date: '2024-06-07', event: 'WORLD FOOD SAFETY DAY PRE-PRIMARY TO - 12', type: 'competition' },
    { date: '2024-06-12', event: 'PALKHI MAKING COMPETITION (MODELS)', type: 'competition' },
    { date: '2024-06-12', event: 'BEST OUT OF WASTE (6-8)', type: 'competition' },
    { date: '2024-06-13', event: 'FANCY DRESS COMPETITION (CLASS 1 - 2)', type: 'competition' },
    { date: '2024-06-14', event: 'INVESTITURE CEREMONY', type: 'pre-board' },
    { date: '2024-06-17', event: 'BAKRI ID', type: 'holiday' },
    { date: '2024-06-21', event: 'INTERNATIONAL YOGA DAY', type: 'competition' },
    { date: '2024-06-22', event: 'FATHER\'S DAY FOR PRE-PRIMARY TO CLASS 2', type: 'competition' },
    { date: '2024-06-28', event: 'NATIONAL DOCTOR DAY', type: 'competition' },
    // Add more school days as needed
  ];

  // Helper function to format date in dd/mm/yyyy format
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <>
      <div className={styles.calendar}>
        <div className={styles.calendar_head}>
          <h1>School Calendar</h1>
        </div>

        <div className={styles.calendar_main}>
          <div className={styles.calendar_container}>
            {/* <Calendar 
              
              onChange={onChange} 
              value={date} 
              tileClassName={({ date, view }) => {
                const event = schoolDays.find(d => new Date(d.date).toDateString() === date.toDateString());
                if (event) {
                  return styles[event.type];
                }
              }}
            /> */}
            <img src={Calendarimg} alt='Hillgreen School calender' />
          </div>
          <div className={styles.events_list}>
            {/* <h2>School Days Information</h2>
            <ul>
              {schoolDays.map((day, index) => (
                <li key={index}>
                  <span className={styles.event_date}>{formatDate(day.date)} -</span><span className={styles.event_name}>{day.event}</span>
                </li>
              ))}
            </ul> */}
            <img src={events} alt='School Events List' />
          </div>
        </div>

        <div className={styles.legend}>
          <h3>Colour Indicators</h3>
          <ul>
            <li><span className={styles.preBoard}></span> Pre-Board</li>
            <li><span className={styles.competition}></span> Competition</li>
            <li><span className={styles.holiday}></span> Holiday</li>
            <li><span className={styles.vacation}></span> Vacation</li>
            <li><span className={styles.exams}></span> Exams</li>
            <li><span className={styles.olympiad}></span> Olympiad</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SchoolCalendar;
