import React from 'react';
import styles from './Why.module.css';
import school from '../../../Assets/Home/school.webp';
import ScrollAnimation from 'react-animate-on-scroll';

const Why = () => {
  return (
    <div className={styles.why_hillgreen}>
      <div className={styles.why_flex}>
        <div className={styles.why_box}>
          <ScrollAnimation animateIn='fadeInUp'>
            <div className={styles.why_image_holder}>
              <img src={school} alt='Hillgreen School'/>
            </div>
          </ScrollAnimation>
        </div>
        <div className={styles.why_box}>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <div className={styles.why_content}>
              <h2>To Make A Bright Future</h2>
              <h3>Change In Education! Need Of The Hour</h3>
              <p>The future is entirely a new kind of world with new kind of needs. There are new types of Jobs to address these changing needs. The needs for new kind of competencies arise to suit these new jobs. We require an effective alternate system of education.</p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className={styles.why_we_are}>
        <div className={styles.why_we_are_head}>
          <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
            <h2>Why We Are?</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>We help you see the world differently, discover opportunities you may never have imagined and achieve results that bridge what is with what can be.</p>
          </ScrollAnimation>
        </div>
        <div className={styles.why_we_are_main}>
          <div className={styles.wrapper}>
            <div className={`${styles.two} ${styles.grid_box}`}>
              <ScrollAnimation animateIn='fadeInUp' delay={200}>
                <div className={styles.triangle_topright}></div>
                <h2 style={{color: 'red'}}>Meets Natural Needs</h2>
                <p>In Hill Green, we follow the natural needs of the child. These needs change as the child grows through different stage of development, and the work given is perfectly matched to child.</p>
                <h2 style={{color: 'red'}}>Unfolds True Potential</h2>
                <p>If all the needs of the child are met at the correct time, the child experiences an unfolding of his true potential, a path that sees the child grow without limitations, leading to a future of opportunity.</p>
              </ScrollAnimation>
            </div>
            <div className={`${styles.three} ${styles.grid_box}`}></div>
            <div className={`${styles.five} ${styles.grid_box}`}>
              <ScrollAnimation animateIn='fadeInUp' delay={200}>
                <div className={styles.triangle_topright}></div>
                <h2 style={{color: 'red'}}>Strong Fundamentals</h2>
                <p>In Hill Green, we place emphasis on self-exploration and learning. This leads to a deep understanding of concepts and strong fundamental base furthering the ability to think and grasp complexity.</p>
                <h2 style={{color: 'red'}}>Holistic Development</h2>
                <p>Rather than limiting the child to isolated academic facts, the Hill Green child undertakes a path of holistic development that has emphasis on the social and moral development as well as academic.</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
