import React from 'react'
import styles from './Campus.module.css'
import { Helmet } from 'react-helmet';

// Import images
import campus1 from '../../../Assets/About/campus1.webp'
import campus2 from '../../../Assets/About/campus2.webp'
import campus3 from '../../../Assets/About/campus3.webp'
import campus4 from '../../../Assets/About/campus4.webp'

const Campus = () => {
  return (
    <>
     {/* SEO */}
     <Helmet>
        <title>Explore Hillgreen High School Campus | Best School in Pune</title>
        <meta name="description" content="Discover the beautiful Hillgreen High School campus with state-of-the-art facilities, expansive outdoor spaces, and a focus on holistic education." />
        <meta name="keywords" content="Hillgreen High School, campus, school facilities, Pune, outdoor learning, school in Pune, experiential learning" />
        
        {/* Open Graph Metadata (for social media sharing) */}
        <meta property="og:title" content="Explore Hillgreen High School Campus | Best School in Pune" />
        <meta property="og:description" content="Take a virtual tour of Hillgreen High School's campus, featuring cutting-edge facilities, green spaces, and a commitment to experiential learning." />
        <meta property="og:image" content={campus1} /> {/* Choose an image representing the campus */}
        <meta property="og:url" content="https://www.hillgreenhighschool.in/campus" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card Metadata */}
        <meta name="twitter:title" content="Explore Hillgreen High School Campus | Best School in Pune" />
        <meta name="twitter:description" content="Join us on a virtual tour of Hillgreen High School, a premier educational institution in Pune offering world-class facilities and experiential learning." />
        <meta name="twitter:image" content={campus1} /> {/* Choose an image for Twitter share */}
        <meta name="twitter:card" content="summary_large_image" />

        {/* Optional: Other meta tags for social sharing */}
        <meta name="robots" content="index, follow" /> {/* Tells search engines to index this page */}
      </Helmet>


    <section className={styles.campus}>
      <header className={styles.campus_head}>
        <div className={styles.overlay}></div>
        <div className={styles.campus_head_text}>
          <h1>Campus</h1>
        </div>
      </header>

      <div className={styles.campus_main}>
        <div className={styles.campus_flex}>
          <article className={styles.campus_text}>
            <h2>Beyond the wall</h2>
            <p>We believe that learning should not be limited by the walls of the campus. Children should be given ample opportunity to go out, see and experience life as it is in the real world. This will introduce them to reality and help them mature into complete and self-reliant human beings. To this effect, many expeditions and outings are organised where groups of children are taken to factory sites, science laboratories, zoological parks, museums, historical and cultural sites and other places of interest. Treks, nature trails, and picnics are also regularly organised. These are not only enjoyable outings for the children, but are also useful learning tools, helping to educate them about various flora and fauna. We also take the children to visit villages and farms around the school campus, and encourage them to interact with the local, rural populace. Such activities open up new windows in the minds of children and expose them to varied and diverse stimuli, which go a long way in nurturing healthy thinking minds. Also the fact that it exposes them to a myriad different social environments.</p>
          </article>
          <aside className={styles.campus_images}>
            <div className={styles.images_grid}>
              <img src={campus1} alt='View of Hillgreen School Campus' />
              <img src={campus2} alt='Students at Hillgreen School Campus' />
              <img src={campus3} alt='Outdoor activities at Hillgreen School Campus' />
              <img src={campus4} alt='Classroom environment at Hillgreen School Campus' />
            </div>
          </aside>
        </div>
      </div>
    </section>
    </>
  )
}

export default Campus
