// Coordinators
import c1 from '../../../Assets/About/Teachers/coordinators/JUHITAPARIA.JPG'
import c2 from '../../../Assets/About/Teachers/coordinators/NISHAVERMA.JPG'
import c3 from '../../../Assets/About/Teachers/coordinators/NAMIRACONTRACTOR.JPG'
import c4 from '../../../Assets/About/Teachers/coordinators/NEHASINGH.JPG'
import c5 from '../../../Assets/About/Teachers/coordinators/prajisha-anoop.webp'

// Hod's

import h1 from '../../../Assets/About/Teachers/hods/AJAY_GAIKWAD.png'
import h2 from '../../../Assets/About/Teachers/hods/ANJALI_SINGH.JPG'
import h3 from '../../../Assets/About/Teachers/hods/AVINASH_BHOSALE.JPG'
import h4 from '../../../Assets/About/Teachers/hods/FARZANA_SAYYED.JPG'
import h5 from '../../../Assets/About/Teachers/hods/HARMEET_SHARMA.JPG'
import h6 from '../../../Assets/About/Teachers/hods/HARSHALI_NIKUMBH.JPG'
import h7 from '../../../Assets/About/Teachers/hods/JAYNT_MHASKE.JPG'
import h8 from '../../../Assets/About/Teachers/hods/MALTI_ASWAL.JPG'
import h9 from '../../../Assets/About/Teachers/hods/NIKITA_ABNAVE.JPG'
import h10 from '../../../Assets/About/Teachers/hods/RADHIKA_PANWAR.JPG'
import h11 from '../../../Assets/About/Teachers/hods/RAKHI_BURSE.JPG'
import h12 from '../../../Assets/About/Teachers/hods/S. ANUSHREE_RAO.JPG'
import h13 from '../../../Assets/About/Teachers/hods/ROHINI_SALUNKHE.JPG'
import h14 from '../../../Assets/About/Teachers/hods/SHEETAL_BHOSALE.JPG'
import h15 from '../../../Assets/About/Teachers/hods/SININ_SAYYED.JPG'


// Teachers Staff
import T1 from '../../../Assets/About/Teachers/aasma_shaikh.webp';
import T2 from '../../../Assets/About/Teachers/aakash_suryawanshi.webp';
import T3 from '../../../Assets/About/Teachers/alimoon-shaikh.webp';
import T4 from '../../../Assets/About/Teachers/anitha-cherry.webp';
import T5 from '../../../Assets/About/Teachers/avinash-patankar1.webp';
import T6 from '../../../Assets/About/Teachers/ayesha-haware.webp';
import T7 from '../../../Assets/About/Teachers/ayesha-mulani.webp';
import T8 from '../../../Assets/About/Teachers/bandana-maithy.webp';
import T9 from '../../../Assets/About/Teachers/bhanupriya-g.webp';
import T10 from '../../../Assets/About/Teachers/bhawna-jham.webp';
import T11 from '../../../Assets/About/Teachers/disha_giwani.JPG';
import T12 from '../../../Assets/About/Teachers/farhana-momin.webp';
import T13 from '../../../Assets/About/Teachers/farida-virapurwala.webp';
import T14 from '../../../Assets/About/Teachers/Fiza-Khan.webp';
import T15 from '../../../Assets/About/Teachers/gajanan-jadhav.webp';
import T16 from '../../../Assets/About/Teachers/aiman_hussain.webp';
import T17 from '../../../Assets/About/Teachers/jasmeet-martin.webp';
import T18 from '../../../Assets/About/Teachers/kmvaishakh.webp';
import T19 from '../../../Assets/About/Teachers/kavya-kamble.webp';
import T20 from '../../../Assets/About/Teachers/khaleda-tamboli.webp';
import T21 from '../../../Assets/About/Teachers/madhuri-pareek.webp';
import T22 from '../../../Assets/About/Teachers/mitali-nair.webp';
import T23 from '../../../Assets/About/Teachers/mohit-dhangar.webp';
import T24 from '../../../Assets/About/Teachers/namrata-mishra.webp';
import T25 from '../../../Assets/About/Teachers/nandini-dixit.webp';
import T26 from '../../../Assets/About/Teachers/noorjahan-shaikh.webp';
import T27 from '../../../Assets/About/Teachers/pallavi-shetty.webp';
import T28 from '../../../Assets/About/Teachers/prabdeep-arora.webp';
import T29 from '../../../Assets/About/Teachers/pragati-tayshete.webp';
import T30 from '../../../Assets/About/Teachers/preeti-garg.webp';
import T31 from '../../../Assets/About/Teachers/preeti-hans.webp';
import T32 from '../../../Assets/About/Teachers/rajitha-vv.webp';
import T33 from '../../../Assets/About/Teachers/rajani_mahiyah.JPG';
import T34 from '../../../Assets/About/Teachers/rekha-shinde.webp';
import T35 from '../../../Assets/About/Teachers/ritu-dev.webp';
import T36 from '../../../Assets/About/Teachers/rohit-jadhav.webp';
import T37 from '../../../Assets/About/Teachers/roohi-saleem.webp';
import T38 from '../../../Assets/About/Teachers/saira-cruz.webp';
import T39 from '../../../Assets/About/Teachers/sakina-contractor.webp';
import T40 from '../../../Assets/About/Teachers/sana-patel.webp';
import T41 from '../../../Assets/About/Teachers/sarvar-shaikh.webp';
import T42 from '../../../Assets/About/Teachers/shabnam-khan.webp';
import T43 from '../../../Assets/About/Teachers/shewta-tembe.webp';
import T44 from '../../../Assets/About/Teachers/shrishti-sinha.webp';
import T45 from '../../../Assets/About/Teachers/sunanda-nagare.webp';
import T46 from '../../../Assets/About/Teachers/supriya-singh.webp';
import T47 from '../../../Assets/About/Teachers/suvarna-kanade.webp';
import T48 from '../../../Assets/About/Teachers/sweety-shaikh.webp';
import T49 from '../../../Assets/About/Teachers/shewta-malhotra.webp';
import T50 from '../../../Assets/About/Teachers/tasneem-rimaswala.webp';
import T51 from '../../../Assets/About/Teachers/vijay-naik.webp';
import T52 from '../../../Assets/About/Teachers/zeenath-shaikh.webp';
import T53 from '../../../Assets/About/Teachers/saba-shaikh.webp';
import T54 from '../../../Assets/About/Teachers/seema-gawali.webp';
import T55 from '../../../Assets/About/Teachers/karan_gadge.png';
import T56 from '../../../Assets/About/Teachers/pratiksha-gaikwad.webp'



const teachersData = [
  {
    category: "COORDINATORS",
    teachers: [
      { id: 1, name: "Juhi Taparia", designation: "Coordinator", image:c1 },
      { id: 2, name: "Nisha Varma", designation: "Coordinator",image:c2 },
      { id: 3, name: "Namira Yunus Contractor", designation: "Coordinator", image:c3 },
      { id: 4, name: "Neha Singh", designation: "Coordinator" , image:c4},
      { id: 5, name: "Prajisha Nambiar", designation: "Principal's PA", image:c5 }
    ]
  },
  {
    category: "HOD'S",
    teachers: [
      { id: 6, name: "Ajay  Gaikwad", designation: "HOD", image:h1 },
      { id: 7, name: "Anjali Mahesh Singh", designation: "HOD", image:h2 },
      { id: 8, name: "Avinash  Bhosale", designation: "HOD" , image:h3 },
      { id: 9, name: "Farzana Mubin Sayed", designation: "HOD", image:h4 },
      { id: 10, name: "Harmeet Kaur ", designation: "HOD", image:h5 },
      { id: 11, name: "Harshali Suraj Mistri", designation: "HOD", image:h6 },
      { id: 12, name: "Jayant Shivaji Maske", designation: "HOD" , image:h7},
      { id: 13, name: "Malti Aswal", designation: "HOD", image:h8 },
      { id: 14, name: "Nikita Abnave", designation: "HOD", image:h9 },
      { id: 15, name: "Radhika Anil Panwar", designation: "HOD" , image:h10},
      { id: 16, name: "Rakhi Sandeep Burse", designation: "HOD" , image:h11},
      { id: 17, name: "Rao  S. Anushree", designation: "HOD" , image:h12},
      { id: 18, name: "Rohini Anil Salunkhe", designation: "HOD", image:h13 },
      { id: 19, name: "Sheetal Sunil Bhosle", designation: "HOD" , image:h14},
      { id: 20, name: "Sinin Arif Sayed", designation: "HOD", image:h15 }
    ]
  },
  {
    category: "TEACHING STAFF",
    teachers: [
      { id: 21, name: "Aasma Zaheer Shaikh", designation: "Teacher", image: T1 },
      { id: 22, name: "Akash Suryavanshi", designation: "Teacher", image: T2 },
      { id: 23, name: "Alimoon Shaikh", designation: "Teacher", image: T3 },
      { id: 24, name: "Anitha Cherry", designation: "Teacher", image: T4 },
      { id: 25, name: "Avinash A Patankar", designation: "Teacher", image: T5 },
      { id: 26, name: "Ayesha Akib Haware", designation: "Teacher", image: T6 },
      { id: 27, name: "Ayesha  Mulani", designation: "Teacher", image: T7 },
      { id: 28, name: "Bandana Sourudra Maity", designation: "Teacher", image: T8 },
      { id: 29, name: "Banupriya G", designation: "Teacher", image: T9 },
      { id: 30, name: "Bhawna Jham", designation: "Teacher", image: T10 },
      { id: 31, name: "Disha Giwani", designation: "Teacher", image: T11 },
      { id: 32, name: "Farhana Mohsin Momin", designation: "Teacher", image: T12 },
      { id: 33, name: "Farida Saifee Virpurwala", designation: "Teacher", image: T13 },
      { id: 34, name: "Fiza Usman Khan", designation: "Teacher", image: T14 },
      { id: 35, name: "Gajanan Jadhav", designation: "Teacher", image: T15 },
      { id: 36, name: "Aiman Hussain", designation: "Teacher", image: T16 },
      { id: 37, name: "Jasmeet Bobby Martin", designation: "Teacher", image: T17 },
      { id: 38, name: "K M Vaishakh", designation: "Teacher", image: T18 },
      { id: 39, name: "Kavya Kaviraj Kamble", designation: "Teacher", image: T19 },


      // { id: 40, name: "Khaleda Begum  Tamboli", designation: "Teacher", image: T20 },


      { id: 41, name: "Madhuri Narayan Pareek", designation: "Teacher", image: T21 },
      { id: 42, name: "Mitali Ashish Nair", designation: "Teacher", image: T22 },
      { id: 43, name: "Mohit Dhangar", designation: "Teacher", image: T23 },
      { id: 44, name: "Namrata Amit Mishra", designation: "Teacher", image: T24 },
      { id: 45, name: "Nandini Pradeep Dixit", designation: "Teacher", image: T25 },
      { id: 46, name: "Noorjahan Shaikh", designation: "Teacher", image: T26 },
      { id: 47, name: "Pallavi Nikitesh Shetty", designation: "Teacher", image: T27 },
      { id: 48, name: "Prabhdeep Kaur", designation: "Teacher", image: T28 },
      { id: 49, name: "Pragati Tayshete", designation: "Teacher", image: T29 },
      { id: 50, name: "Preeti Ashish Garg", designation: "Teacher", image: T30 },
      { id: 51, name: "Priti Hans", designation: "Teacher", image: T31 },
      { id: 52, name: "Rajitha V V", designation: "Teacher", image: T32 },
      { id: 53, name: "Rajani Mahiyah", designation: "Teacher", image: T33 },
      
      // { id: 55, name: "Ritu Nikhil Dev", designation: "Teacher", image: T35 },

      
      { id: 56, name: "Rohit S Jadhav", designation: "Teacher", image: T36 },
      { id: 57, name: "Roohi Irfan Salim", designation: "Teacher", image: T37 },
      { id: 58, name: "Saira Sandeep Cruz", designation: "Teacher", image: T38 },
      { id: 59, name: "Sakina Yunus Contractor", designation: "Teacher", image: T39 },
      { id: 60, name: "Sana Salman Patel", designation: "Teacher", image: T40 },
      { id: 61, name: "Sarvar Shafi Shaikh", designation: "Teacher", image: T41 },
      { id: 62, name: "Shabnam Khan", designation: "Teacher", image: T42 },
      { id: 63, name: "Shweta Tembe", designation: "Teacher", image: T43 },
      { id: 64, name: "Srishti Sinha", designation: "Teacher", image: T44 },
      { id: 65, name: "Sunanda R Nagare", designation: "Teacher", image: T45 },
      { id: 66, name: "Supriya Singh", designation: "Teacher", image: T46 },
      { id: 67, name: "Suvarna Sandeep Kanade", designation: "Teacher", image: T47 },
      { id: 68, name: "Sweety Shaikh", designation: "Teacher", image: T48 },
      
      { id: 70, name: "Tasneem  Primaswala", designation: "Teacher", image: T50 },
      { id: 71, name: "Vijay Naik", designation: "Teacher", image: T51 },
      { id: 72, name: "Zeenat Shaikh", designation: "Teacher", image: T52 },
      { id: 73, name: "Saba Shaikh", designation: "Teacher", image: T53 },
      { id: 74, name: "Seema S.Gawali", designation: "Teacher", image: T54 },
      { id: 75, name: "Karan Gadge", designation: "Teacher", image: T55 },
      { id: 54, name: "Rekha Shinde", designation: "Librarian", image: T34 },
      { id: 69, name: "Sweta Malhotra", designation: "counselor ", image: T49 },
      { id: 76, name: "Pratiksha Gaikwad", designation: "Nursing Assistant",  image: T56}

    ]
  }
];

export default teachersData;