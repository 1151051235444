import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "Which is the medium of instruction in school?",
            answer: "It's an English medium school."
        },
        {
            question: "What should be my child's age in-order to acquire admission in grade nursery/LKG/UKG?",
            answer: "Nursery: 3 years and above as on 1st April.\nLKG: 4 years and above as on 1st April.\nU.K.G: 5 years and above as on 1st April."
        },
        {
            question: "Is there an entrance test for children seeking admission?",
            answer: "Primary School and Middle School: No written tests are required for primary and middle classes. Here, the admission can be confirmed after an interaction.\nSenior School: Admission to senior classes can be attained after the child attempts the entrance tests for three subjects, i.e – English, Science and Mathematics."
        },
        {
            question: "Do you provide School Bus facility?",
            answer: "Buses are owned by the society. The school does not own any bus."
        },
        {
            question: "What about the security of my ward?",
            answer: "Your ward is completely safe in our school. Entire campus, common area as well the class rooms and school buses are under CCTV surveillance. Two checkpoints at school entrance. School bus is having lady attendants. Safety audit has been done of entire school and Safe school certificate issued by Bureau Veritas."
        },
        {
            question: "Which all extracurricular activities are conducted in school?",
            answer: "School has variety of extracurricular activities. In sports school has Football, Basketball, Volleyball, Chess, Skating and many more games. School has well equipped Gym facility. Apart of sports Dance, Music, Martial arts are the part of the daily curriculum. We organize Inter school and Intra school debates, Elocutions, Quiz, and art competitions regularly. We arrange several field visits related to science, history, geography, environment etc."
        }
    ];

    return (
        <div className={styles.faq}>
            <div className={styles.faq_head}>
                <div className={styles.overlay}></div>
                <div className={styles.faq_head_text}>
                    <h1>Most Popular Questions</h1>
                </div>
            </div>
            <div className={styles.faq_main}>
                {faqData.map((item, index) => (
                    <div key={index} className={styles.faq_item}>
                        <div className={styles.faq_question} onClick={() => toggleAccordion(index)}>
                            {item.question}
                            <span>{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        <div className={`${styles.faq_answer} ${activeIndex === index ? styles.active : ''}`}>
                            {item.answer.split('\n').map((line, idx) => (
                                <p key={idx}>{line}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Faq;
