import React from 'react';
import styles from './Intro.module.css';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const Intro = () => {
    return (
        <section className={styles.introduction} aria-label="School Introduction">
            <div className={styles.overlay}></div>
            <div className={styles.flex_history}>
                <div className={styles.box}>
                    <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
                        <h2>History of the School</h2>
                    </ScrollAnimation>
                </div>

                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className={styles.box}>
                        <p>KERALA PEOPLES EDUCATION SOCIETY (KPES) established in the year 1964 as an Education Institution is registered under the Societies and Trust act. This Institution is a Linguistic minority institution.</p>
                        <p>The main objective of this Trust was to part quality education at affordable fees to the needy students. With this objective in mind we established a school in the name of Jawarlal English.</p>
                        <Link to='/schoolhistory' className={styles.main_button}>Read More</Link>
                    </div>
                </ScrollAnimation>
            </div>
        </section>
    );
}

export default Intro;
