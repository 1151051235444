import React from 'react';
import styles from './Faculty.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import fac2 from '../../../Assets/Home/Fac_2.webp';
import logo from '../../../Assets/Navbar/Logo.png';
import { Link } from 'react-router-dom';

const Faculty = () => {
  return (
    <section className={styles.faculty} aria-labelledby="faculty-heading">
      <h2 id="faculty-heading" className={styles.hidden}>Faculty Highlights</h2>
      <div className={styles.grid}>
        {/* Box 2 */}
        <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' style={{ width: '300px', margin: 'auto' }}>
          <div className={styles.grid_box}>
            <img src={fac2} alt='Hillgreen Celebrating 27 Years' />
            <h3>CELEBRATING 27 YEARS</h3>
            <p>Hillgreen High School celebrating 27 Years of excellence…</p>
            <Link to='/schoolhistory'><button className={styles.read_button}>Read more</button></Link>
          </div>
        </ScrollAnimation>

        {/* Box 3 */}
        <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' style={{ width: '300px', margin: 'auto' }}>
          <div className={styles.grid_box}>
            <img src={logo} alt='About Hillgreen' />
            <h3>ABOUT HILLGREEN</h3>
            <p>HHS is yet another prestigious project of Kerala People's Education Society.</p>
            <Link to='/schoolhistory'><button className={styles.read_button}>Read more</button></Link>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
}

export default Faculty;
