import React from 'react'
import styles from './Indoor.module.css'

// Import Images and other files
import artncraft from '../../../Assets/Activities/indoor_chess.webp'
import drama from '../../../Assets/Activities/chess2.webp'
import board from '../../../Assets/Activities/board.webp'


const Indoore = () => {
    return (
        <>
            <div className={styles.indoor}>
                <div className={styles.indoor_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.indoor_head_text}>
                        <h1>Indoor</h1>
                    </div>
                </div>
                <div className={styles.indoor_main}>
                    <div className={styles.indoor_activities}>
                        <h1>Indoor Activities</h1>
                        <p>Dramatics, Elocution, Debates, Singing, Music, and Chess are some of the indoor activities held. Apart from this, the students are also involved in social service, educational tours and a host of other activities.</p>
                        <br/>
                        <p>Since it is our endeavour to cater to the all-round development of our students' personalities, and prepare each one to face life as a leader of the future, we give a lot of importance to the co-curricular activities, as it is these activities that develop certain qualities of leadership such as initiative, team work, co-operation, quick thinking, etc. Various responsibilities are given to students which they are expected to carry out diligently, under the guidance of the staff. Students are also given appointments through a system of prefects, captains and monitors, who have the additional responsibility of maintaining discipline along with their normal responsibilities.</p>
                    </div>

                    <div className={`${styles.art_craft}`}> 
                        <div className={styles.art_frat_box}>
                            <img  src={artncraft} alt='Hillgreen Art and Craft ' style={{width:'100%'}} />
                        </div>

                        <div className={styles.art_frat_box}>
                                <p>At Hillgreen High School, we pride ourselves on offering a diverse range of indoor activities that cater to the varied interests and talents of our students. Our art and craft classes provide a creative outlet where students can explore different mediums and techniques. In our music and dance classes, students have the opportunity to express themselves through rhythm and melody, learning various instruments and dance styles.</p>
                        </div>
                         
                    </div>

                    <div className={`${styles.art_craft} ${styles.reverse}`}> 
                        <div className={styles.art_frat_box}>
                                <p>At Hillgreen High School, we pride ourselves on offering a diverse range of indoor activities that cater to the varied interests and talents of our students. Our art and craft classes provide a creative outlet where students can explore different mediums and techniques. In our music and dance classes, students have the opportunity to express themselves through rhythm and melody, learning various instruments and dance styles.</p>
                        </div>
                        <div className={styles.art_frat_box}>
                            <img  src={drama} alt='Hillgreen Art and Craft ' />
                        </div> 
                    </div>

                    <div className={styles.art_craft}> 
                        <div className={styles.art_frat_box}>
                            <img  src={board} alt='Hillgreen Art and Craft ' />
                        </div>
                        <div className={styles.art_frat_box}>
                                <p>Hillgreen's library and reading clubs inspire a love for literature, and our board games and puzzles sessions promote strategic and cooperative play. Our ICT classes ensure students are proficient in essential computer skills. We also offer yoga and meditation sessions to support students' mental and physical well-being.</p>
                        </div>                         
                    </div>
                </div>
            </div>
        </>
    )
}

export default Indoore