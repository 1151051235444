import React from 'react';
import styles from './Testimonials.module.css';

const Testimonials = () => {
    return (
        <>
            <div className={styles.testimonials}>
                <div className={styles.testimonials_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.testimonials_head_text}>
                        <h1>Testimonials</h1>
                    </div>
                </div>
                
                <div className={styles.testimonials_main}>
                    <div className={styles.video_grid}>
                        <iframe width="354" height="198" src="https://www.youtube.com/embed/G3gnp7JLS_k" title="HILLGREEN HIGH SCHOOL &amp; JC PUNE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="354" height="198" src="https://www.youtube.com/embed/G3gnp7JLS_k" title="HILLGREEN HIGH SCHOOL &amp; JC PUNE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="354" height="198" src="https://www.youtube.com/embed/G3gnp7JLS_k" title="HILLGREEN HIGH SCHOOL &amp; JC PUNE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonials;
