import React from 'react'
import styles from './Clubs.module.css'


// import images or other files 
import ECOHARMONY from '../../../Assets/Activities/Clubs/ECO.webp'
import NUTRICUISINE from '../../../Assets/Activities/Clubs/NUTRICUISINE.webp'
import FLORA from '../../../Assets/Activities/Clubs/FLORA.webp'
import FIT from '../../../Assets/Activities/Clubs/FIT&FLOURISH.webp'
import RRR from '../../../Assets/Activities/Clubs/R_R_R.webp'

import BRAINIAC from '../../../Assets/Activities/Clubs/BRAINIAC.webp'
import ROOTS from '../../../Assets/Activities/Clubs/ROOTS.webp'

import ROUTINE from '../../../Assets/Activities/Clubs/ROUTINE.webp'
import THRIVE  from '../../../Assets/Activities/Clubs/THRIVE.webp'

import ORATOR from '../../../Assets/Activities/Clubs/ORATOR.webp'

import Gardening from '../../../Assets/Activities/Gardening.webp'
import health from '../../../Assets/Activities/health_wellness.webp'



const Clubs = () => {
    return (
        <>
            <div className={styles.clubs}>
                <div className={styles.club_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.clubs_head_text}>
                        <h1>Clubs</h1>
                    </div>
                </div>

                <div className={styles.clubs_main}>
                    <div className={styles.clubs_grid}>
                        <div className={styles.club_grid_box}>
                            <img src={ECOHARMONY} alt='Hillgreen ECO HARMONY CLUB' />
                            <h2>ECO HARMONY CLUB</h2>
                            <p>An ECO club, also known as an
                                Environmental Club focus on a variety of
                                activities aimed at improving and
                                protecting the environment.
                            </p>
                        </div>
                        <div className={styles.club_grid_box}>
                            <img src={NUTRICUISINE} alt='Hillgreen NUTRICUISINE CLUB' />
                            <h2>NUTRICUISINE CLUB</h2>
                            <p>A NUTRICUISINE CLUB focuses on
                                promoting healthy eating habits and
                                values of nutrition through various
                                culinary activities.</p>
                        </div>

                        <div className={styles.club_grid_box}>
                            <img src={FLORA} alt='Hillgreen FLORA FRIENDS CLUB ' />
                            <h2>FLORA FRIENDS CLUB</h2>
                            <p>Flora friends club or gardening club
                                will improve gardening skills while
                                connecting with a supportive community.
                            </p>
                        </div>


                        <div className={styles.club_grid_box}>
                            <img src={FIT} alt='Hillgreen FIT & FLOURISH CLUB ' />
                            <h2>FIT & FLOURISH CLUB</h2>
                            <p>Fit & Flourish Club or Health Club will
                                help students learn how to promote a
                                healthy, active, and balanced lifestyle.</p>
                        </div>


                        <div className={styles.club_grid_box}>
                            <img src={RRR} alt='Hillgreen R-R-R CLUB ' />
                            <h2>R-R-R CLUB</h2>
                            <p>R-R-R Club or The Best Out of Waste
                                Club actively promotes the principles of
                                "Reduce-Reuse-Recycle" and explores
                                innovative ways to foster a healthy
                                environment within our community.
                            </p>
                        </div>




                        <div className={styles.club_grid_box}>
                            <img src={BRAINIAC} alt='Hillgreen BRAINIAC Club' />
                            <h2>BRAINIAC CLUB</h2>
                            <p>The Brainiac Club fosters the
                                development of aptitude skills such as
                                numerical, verbal and logical reasoning. It
                                encourages students to explore and
                                expand their cognitive abilities.</p>
                        </div>


                        <div className={styles.club_grid_box}>
                            <img src={ROOTS} alt='Hillgreen ROOTS & ROUTES CLUB ' />
                            <h2>ROOTS & ROUTES CLUB </h2>
                            <p>ROOTS & ROUTES CLUB helps
                                students learn about the Heritage of our
                                country like cultural roots, traditions, and
                                history.</p>
                        </div>


                        <div className={styles.club_grid_box}>
                            <img src={ROUTINE} alt='Hillgreen ROUTINE RHYTHM CLUB' />
                            <h2>ROUTINE RHYTHM CLUB</h2>
                            <p>ROUTINE RHYTHM club empowers
                                tools, knowledge, and inculcates daily
                                routine skills more efficiently to foster
                                independency.
                            </p>
                        </div>

                        <div className={styles.club_grid_box}>
                            <img src={THRIVE} alt='Hillgreen THRIVE SKILLS CLUB' />
                            <h2>THRIVE SKILLS CLUB</h2>
                            <p>THRIVE SKILLS CLUB develops life
                                skills which includes cognitive, social and
                                behavioral skills by which students lead
                                smoother transitions towards being
                                responsible adults.
                            </p>
                        </div>

                        <div className={styles.club_grid_box}>
                            <img src={ORATOR} alt='Hillgreen ORATOR’S CLUB' />
                            <h2>ORATOR’S CLUB</h2>
                            <p>The ORATOR’S club provides
                                opportunities for developing Public
                                Speaking abilities, Communication Skills
                                and Personality Development.
                            </p>
                        </div>

                        <div className={styles.club_grid_box}>
                            <img src={Gardening} alt='Hillgreen Gardening Club' />
                            <h2>HEART & HANDS CLUB </h2>
                            <p>HEART & HANDS CLUB or social service
                                club focuses to make a positive difference in
                                the lives of others and contribute to the
                                greater good of society through service,
                                compassion, and advocacy.
                            </p>
                        </div>

                        <div className={styles.club_grid_box}>
                            <img src={health} alt='Hillgreen health Club' />
                            <h2>THINK TANK CLUB</h2>
                            <p>THINK TANK CLUB aim to promote
                                scientific curiosity, exploration, and
                                research among students or enthusiasts.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Clubs